import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/layout"

export default function Faq() {
  return (
    <Layout>
      <Helmet>
        <body className="faq single-page" />
      </Helmet>
      <article>
        <h1>FAQ</h1>
        <section className="questions-wrapper">
          <h2>Do you accept returns and/or exchanges?</h2>
          <p>
            We are a small company, literally a mom and pop operation. At this
            time, we are not accepting returns unless you received a damaged
            product. In the future, as we expand our product offerings, we
            will reconsider this one.
          </p>
          <h2>Do you ship internationally?</h2>
          <p>
            We do not currently ship internationally. This is something we are
            working on. If you are outside of the United States and interested
            in our products,{" "}
            <a href="mailto:support@averygoodplan.com">drop us a line</a>. It’ll
            motivate us to move forward with it.
          </p>
        </section>
        <section className="bottom-content">
          <h3>For any other questions:</h3>
          <p>
            You can email us at{" "}
            <a href="mailto:support@averygoodplan.com">support@averygoodplan.com</a>
          </p>
        </section>
      </article>
    </Layout>
  )
}
